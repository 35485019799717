<template>
    <div class="section">
        <div class="section__wrapper">
            <div class="section__limiter"
                 v-if="limiter">
                <slot/>
            </div>
            <slot v-else/>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    limiter: {
      type: Boolean,
    },
  },
};
</script>
